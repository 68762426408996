import React, { useCallback, useEffect, useRef, useState } from 'react'
import T from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Formik, Form } from 'formik'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'

import {
  Box,
  Button,
  Container,
  Stack,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import useFetch from '../../../hooks/useFetch'
import useFetchRideTypes from '../../../hooks/useFetchRideTypes'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'

import TextField from '../../atoms/TextField'
import Spinner from '../../atoms/Spinner'
import HeadquartersAndSectors from '../../molecules/HeadquartersAndSectors'

import request from '../../../lib/request'
import { ChallengeSchema } from './validators'

export default function ChallengeForm({ companyId, challengeId }) {
  const formikRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const rideTypes = useFetchRideTypes()

  const isNew = !challengeId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const componentName = 'Challenge'

  const fetchChallenge = useCallback(async () => {
    if (!challengeId) {
      return null
    }

    return request.get(`/admin/challenges/${challengeId}`)
  }, [challengeId])

  const {
    fetchedObject: challenge,
    isFetching,
    fetchError,
  } = useFetch(fetchChallenge)

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t(`common.fetchError_${componentName}`, {
          error: fetchError.message,
        }),
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  async function handleDownload(e) {
    e.preventDefault()
    const challengeName = challenge.name.replace(/[ '"/\\]/g, '_')
    const fileName = `${challengeName}-${new Date().toISOString()}.xlsx`
    await request.downloadFile(
      `/admin/challenges/${
        challenge.id
      }/report?timezoneOffset=${new Date().getTimezoneOffset()}`,
      fileName
    )
  }

  async function handleSubmit(values, actions) {
    try {
      const data = {
        companyId,
        name: values.name,
        attrs: {
          startDate: startOfDay(values.startDate).toISOString(),
          endDate: endOfDay(values.endDate).toISOString(),
          publicationDate: startOfDay(values.publicationDate).toISOString(),
          rideTypeIds: values.rideTypeIds,
        },
        headquarterIds: values.headquarterIds.map((h) => h.id),
        sectorIds: values.sectorIds.map((s) => s.id),
      }

      if (isNew) {
        const { id } = await request.post(`/admin/challenges`, data)
        actions.setSubmitting(false)
        history.replace(`/company/${companyId}/challenges/${id}`)
      } else {
        await request.patch(`/admin/challenges/${challengeId}`, {
          ...data,
          id: challengeId,
        })
        actions.setSubmitting(false)
        setPageMode('view')
        Object.assign(challenge, data)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (error) {
      console.error(error.message) // eslint-disable-line
      if (error.response.data.errorCode === 'overlaps')
        return showSnackbarMessage({
          open: true,
          severity: 'error',
          message:
            t(`common.requestError_overlaps`) +
            '. ' +
            t(`common.requestError_overlaps_challenges`) +
            error.response.data.overlappingChallengeNames.join(', '),
        })

      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t(`common.requestError_${componentName}`, {
          error: error.message,
        }),
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('challengeDetailPage.deleteConfirmDescription', {
        name: challenge?.name,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(`/admin/challenges/${challengeId}`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/company/${companyId}/challenges`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleRideTypeChange(event, values, setFieldValue, setFieldTouched) {
    setFieldTouched('rideTypeIds', true)
    if (event.target.checked) {
      setFieldValue('rideTypeIds', [...values.rideTypeIds, event.target.value])
    } else {
      setFieldValue(
        'rideTypeIds',
        values.rideTypeIds.filter((value) => value !== event.target.value)
      )
    }
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0}>
      <Formik
        innerRef={formikRef}
        validationSchema={ChallengeSchema(t)}
        initialValues={{
          name: challenge?.name || '',
          publicationDate: challenge?.attrs?.publicationDate
            ? new Date(challenge.attrs.publicationDate)
            : '',
          startDate: challenge?.attrs?.startDate
            ? new Date(challenge.attrs.startDate)
            : '',
          endDate: challenge?.attrs?.endDate
            ? new Date(challenge.attrs.endDate)
            : '',
          rideTypeIds: [...(rideTypes?.fetchedObject || [])].filter(
            (rideTypeId) => challenge?.attrs?.rideTypeIds?.includes(rideTypeId)
          ),
          headquarterIds: challenge?.headquarters || [],
          sectorIds: challenge?.sectors || [],
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({
          isValid,
          dirty,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <Stack spacing={2} marginTop={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  required
                  inputProps={{
                    autoComplete: 'off',
                    readOnly,
                  }}
                  label={t('entities.challenge.name')}
                  name="name"
                  size="small"
                />
                <Box display="flex" gap={2}>
                  <DatePicker
                    margin="dense"
                    label={t('entities.challenge.publicationDate')}
                    name="publicationDate"
                    autoOk
                    readOnly={readOnly}
                    value={
                      values.publicationDate
                        ? new Date(values.publicationDate)
                        : null
                    }
                    onChange={(newDate) =>
                      setFieldValue('publicationDate', newDate)
                    }
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    size="small"
                    required
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                      size: 'small',
                      required: true,
                    }}
                  />
                  <DatePicker
                    margin="dense"
                    label={t('entities.challenge.startDate')}
                    name="startDate"
                    autoOk
                    readOnly={readOnly}
                    value={values.startDate ? new Date(values.startDate) : null}
                    onChange={(newDate) => setFieldValue('startDate', newDate)}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    size="small"
                    required
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                      size: 'small',
                      required: true,
                    }}
                  />
                  <DatePicker
                    margin="dense"
                    label={t('entities.challenge.endDate')}
                    name="endDate"
                    autoOk
                    readOnly={readOnly}
                    value={values.endDate ? new Date(values.endDate) : null}
                    onChange={(newDate) => setFieldValue('endDate', newDate)}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    size="small"
                    required
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                      size: 'small',
                    }}
                  />
                </Box>
                <Box>
                  <fieldset>
                    <legend>{t('challengeDetailPage.rideTypes')}</legend>
                    {rideTypes?.fetchedObject?.map((rideTypeId) => (
                      <FormControlLabel
                        key={rideTypeId}
                        control={
                          <Checkbox
                            checked={values.rideTypeIds?.includes(rideTypeId)}
                            inputProps={{
                              disabled: readOnly,
                            }}
                            color="primary"
                            name="rideTypeIds"
                            value={rideTypeId}
                            onChange={(e) =>
                              handleRideTypeChange(
                                e,
                                values,
                                setFieldValue,
                                setFieldTouched
                              )
                            }
                          />
                        }
                        label={t(`entities.rideTypes.${rideTypeId}`)}
                      />
                    ))}
                    {errors.rideTypeIds && touched.rideTypeIds && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                        }}
                      >
                        {errors.rideTypeIds}
                      </div>
                    )}
                  </fieldset>
                </Box>

                <HeadquartersAndSectors
                  companyId={companyId}
                  values={values}
                  setFieldValue={setFieldValue}
                  readOnly={readOnly}
                />
              </Stack>

              <Container>
                {readOnly ? (
                  <>
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                    {isAdmin && (
                      <>
                        <Button
                          onClick={handleEdit}
                          color="primary"
                          aria-label={t('common.edit')}
                        >
                          {t('common.edit')}
                        </Button>
                        <Button
                          onClick={handleDelete}
                          color="primary"
                          aria-label={t('common.delete')}
                        >
                          {t('common.delete')}
                        </Button>
                      </>
                    )}
                    <Button
                      onClick={handleDownload}
                      color="primary"
                      aria-label={t('common.report')}
                    >
                      {t('common.report')}
                    </Button>
                  </>
                ) : (
                  <>
                    {isNew ? (
                      <Button
                        onClick={() => history.goBack()}
                        color="secondary"
                        aria-label={t('common.back')}
                      >
                        {t('common.back')}
                      </Button>
                    ) : (
                      <Button
                        type="reset"
                        color="secondary"
                        aria-label={t('common.cancel')}
                      >
                        {t('common.cancel')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={!isValid || !dirty || isSubmitting}
                      color="primary"
                    >
                      {t('common.save')}
                    </Button>
                  </>
                )}
              </Container>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

ChallengeForm.propTypes = {
  companyId: T.string,
  challengeId: T.string,
}
