import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import T from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Formik, Form } from 'formik'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'

import {
  Box,
  Button,
  Container,
  Stack,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  Card,
  CardContent,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import useCompaniesSectors from '../../../hooks/useCompaniesSectors'

import useFetch from '../../../hooks/useFetch'
import useFetchRideTypes from '../../../hooks/useFetchRideTypes'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'

import TextField from '../../atoms/TextField'
import Spinner from '../../atoms/Spinner'

import request from '../../../lib/request'
import ParticipatingCompanies from './ParticipatingCompanies'
import { AgreementSchema } from './validators'

export default function AgreementForm({ agreementId }) {
  const formikRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const companies = useCompaniesSectors().filter((c) => !c.archived)
  const rideTypes = useFetchRideTypes()

  const isNew = !agreementId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const componentName = 'Agreement'

  const fetchAgreement = useCallback(async () => {
    if (!agreementId) {
      return null
    }

    return request.get(`/admin/agreements/${agreementId}`)
  }, [agreementId])

  const {
    fetchedObject: agreement,
    isFetching,
    fetchError,
  } = useFetch(fetchAgreement)

  const formattedParticipatingCompanies = useMemo(() => {
    if (!agreement?.participatingCompanies) {
      return []
    }

    return agreement.participatingCompanies.map((pc) => ({
      ...pc,
      startDate: pc.startDate ? new Date(pc.startDate) : null,
      endDate: pc.endDate ? new Date(pc.endDate) : null,
    }))
  }, [agreement?.participatingCompanies])

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t(`common.fetchError_${componentName}`, {
          error: fetchError.message,
        }),
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        name: values.name,
        startDate: startOfDay(values.startDate).toISOString(),
        endDate: values.endDate && endOfDay(values.endDate).toISOString(),
        promotingCompanyId: values.promotingCompanyId,
        attrs: {
          promotingCompanyEmail: values.promotingCompanyEmail,
          rideTypeIds: values.rideTypeIds,
          kmRefundInEuro: values.kmRefundInEuro,
          maxEuroPerUserMonthly: values.maxEuroPerUserMonthly,
        },
      }

      if (isNew) {
        const { id } = await request.post(`/admin/agreements`, data)
        actions.setSubmitting(false)
        history.replace(`/agreement/${id}`)
      } else {
        await request.patch(`/admin/agreements/${agreementId}`, {
          ...data,
          id: agreementId,
        })
        actions.setSubmitting(false)
        setPageMode('view')
        Object.assign(agreement, data)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (error) {
      console.error(error.message) // eslint-disable-line
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t(`common.requestError_${componentName}`, {
          error: error.message,
        }),
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('agreementDetailPage.deleteConfirmDescription', {
        name: agreement?.name,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(`/admin/agreements/${agreementId}`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/agreements`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleRideTypeChange(event, values, setFieldValue, setFieldTouched) {
    setFieldTouched('rideTypeIds', true)
    if (event.target.checked) {
      setFieldValue('rideTypeIds', [...values.rideTypeIds, event.target.value])
    } else {
      setFieldValue(
        'rideTypeIds',
        values.rideTypeIds.filter((value) => value !== event.target.value)
      )
    }
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <>
      <Box maxWidth={700} ml={0}>
        <Formik
          innerRef={formikRef}
          validationSchema={AgreementSchema(t)}
          initialValues={{
            name: agreement?.name || '',
            startDate: agreement?.startDate
              ? new Date(agreement.startDate)
              : '',
            endDate: agreement?.endDate ? new Date(agreement.endDate) : '',
            rideTypeIds: [...(rideTypes?.fetchedObject || [])].filter(
              (rideTypeId) =>
                agreement?.attrs?.rideTypeIds?.includes(rideTypeId)
            ),
            promotingCompanyId: agreement?.promotingCompanyId || '',
            promotingCompanyEmail:
              agreement?.attrs?.promotingCompanyEmail || '',
            kmRefundInEuro: agreement?.attrs?.kmRefundInEuro || '',
            maxEuroPerUserMonthly:
              agreement?.attrs?.maxEuroPerUserMonthly || '',
          }}
          onSubmit={handleSubmit}
          onReset={handleReset}
          enableReinitialize={true}
        >
          {({
            isValid,
            dirty,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
          }) => {
            return (
              <Form>
                <Stack spacing={2} marginTop={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    required
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                    }}
                    label={t('entities.agreement.name')}
                    name="name"
                    size="small"
                  />
                  <Box display="flex" gap={2}>
                    <DatePicker
                      margin="dense"
                      label={t('entities.agreement.startDate')}
                      name="startDate"
                      autoOk
                      readOnly={readOnly}
                      value={
                        values.startDate ? new Date(values.startDate) : null
                      }
                      onChange={(newDate) =>
                        setFieldValue('startDate', newDate)
                      }
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      size="small"
                      required
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                      inputProps={{
                        autoComplete: 'off',
                        readOnly,
                        size: 'small',
                        required: true,
                      }}
                    />
                    <DatePicker
                      margin="dense"
                      label={t('entities.agreement.endDate')}
                      name="endDate"
                      autoOk
                      readOnly={readOnly}
                      value={values.endDate ? new Date(values.endDate) : null}
                      onChange={(newDate) => setFieldValue('endDate', newDate)}
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      size="small"
                      inputProps={{
                        autoComplete: 'off',
                        readOnly,
                        size: 'small',
                      }}
                    />
                  </Box>

                  <Box>
                    <fieldset>
                      <legend>{t('agreementDetailPage.rideTypes')}</legend>
                      {rideTypes?.fetchedObject?.map((rideTypeId) => (
                        <FormControlLabel
                          key={rideTypeId}
                          control={
                            <Checkbox
                              checked={values.rideTypeIds?.includes(rideTypeId)}
                              inputProps={{
                                disabled: readOnly,
                              }}
                              color="primary"
                              name="rideTypeIds"
                              value={rideTypeId}
                              onChange={(e) =>
                                handleRideTypeChange(
                                  e,
                                  values,
                                  setFieldValue,
                                  setFieldTouched
                                )
                              }
                            />
                          }
                          label={t(`entities.rideTypes.${rideTypeId}`)}
                        />
                      ))}
                      {errors.rideTypeIds && touched.rideTypeIds && (
                        <div
                          style={{
                            color: '#d32f2f',
                            fontSize: '0.75rem',
                          }}
                        >
                          {errors.rideTypeIds}
                        </div>
                      )}
                    </fieldset>
                  </Box>

                  <Box display="flex" gap={2} width="100%">
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      size="small"
                      sx={{ flex: 1 }}
                    >
                      <InputLabel id="promotingCompanyId-label">
                        {t('entities.agreement.promotingCompanyId') + ' *'}
                      </InputLabel>
                      <Select
                        labelId="promotingCompanyId-label"
                        label={t('entities.agreement.promotingCompanyId')}
                        name="promotingCompanyId"
                        value={values.promotingCompanyId}
                        onChange={(e) =>
                          setFieldValue('promotingCompanyId', e.target.value)
                        }
                        disabled={!!readOnly}
                        variant="outlined"
                        required
                      >
                        <MenuItem value="">Scegli</MenuItem>
                        {companies.map((c) => (
                          <MenuItem key={c.id} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      margin="dense"
                      inputProps={{
                        autoComplete: 'off',
                        readOnly,
                      }}
                      label={t('entities.agreement.promotingCompanyEmail')}
                      name="promotingCompanyEmail"
                      size="small"
                      sx={{ flex: 1 }}
                      required
                    />
                  </Box>

                  <Box>
                    <TextField
                      margin="dense"
                      inputProps={{
                        autoComplete: 'off',
                        readOnly,
                      }}
                      label={t('entities.agreement.kmRefundInEuro')}
                      name="kmRefundInEuro"
                      size="small"
                      sx={{ width: 200 }}
                      required
                    />
                    <TextField
                      margin="dense"
                      inputProps={{
                        autoComplete: 'off',
                        readOnly,
                      }}
                      label={t('entities.agreement.maxEuroPerUserMonthly')}
                      name="maxEuroPerUserMonthly"
                      size="small"
                      sx={{ width: 200, marginLeft: '1rem' }}
                      required
                    />
                  </Box>
                </Stack>

                <Container>
                  {readOnly ? (
                    <>
                      <Button
                        onClick={() => history.goBack()}
                        color="secondary"
                        aria-label={t('common.back')}
                      >
                        {t('common.back')}
                      </Button>
                      {isAdmin && (
                        <>
                          <Button
                            onClick={handleEdit}
                            color="primary"
                            aria-label={t('common.edit')}
                          >
                            {t('common.edit')}
                          </Button>
                          <Button
                            onClick={handleDelete}
                            color="primary"
                            aria-label={t('common.delete')}
                          >
                            {t('common.delete')}
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {isNew ? (
                        <Button
                          onClick={() => history.goBack()}
                          color="secondary"
                          aria-label={t('common.back')}
                        >
                          {t('common.back')}
                        </Button>
                      ) : (
                        <Button
                          type="reset"
                          color="secondary"
                          aria-label={t('common.cancel')}
                        >
                          {t('common.cancel')}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        disabled={!isValid || !dirty || isSubmitting}
                        color="primary"
                      >
                        {t('common.save')}
                      </Button>
                    </>
                  )}
                </Container>
              </Form>
            )
          }}
        </Formik>
      </Box>

      {!isNew && (
        <>
          <Divider sx={{ m: 3 }} />

          <Card>
            <CardContent>
              <Typography variant="h5">
                {t('agreementDetailPage.participatingCompanies.title')}
              </Typography>
              <ParticipatingCompanies
                participatingCompanies={formattedParticipatingCompanies}
              />
            </CardContent>
          </Card>
        </>
      )}
    </>
  )
}

AgreementForm.propTypes = {
  agreementId: T.string,
}
