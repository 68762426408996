import React from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@mui/styles'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Paper,
  Typography,
  List,
  Checkbox,
} from '@mui/material'

import useFetchHeadquarters from '../../hooks/useFetchHeadquarters'
import useFetchSectors from '../../hooks/useFetchSectors'

const useStyles = makeStyles(() => ({
  menuHeadquarters: {
    height: 400,
  },
}))

export default function HeadquartersAndSectors({
  companyId,
  values,
  setFieldValue,
  readOnly,
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const headquarters = useFetchHeadquarters({ companyId })
  const sectors = useFetchSectors({ companyId })

  const handleHeadquarterChange = ({ target }) => {
    const { value } = target
    if (value[value.length - 1] === 'all') {
      setFieldValue(
        'headquarterIds',
        values.headquarterIds.length === headquarters.fetchedObject.length
          ? []
          : headquarters.fetchedObject
      )
      return
    }
    setFieldValue('headquarterIds', value)
  }

  const handleSectorChange = ({ target }) => {
    const { value } = target
    if (value[value.length - 1] === 'all') {
      setFieldValue(
        'sectorIds',
        values.sectorIds.length === sectors.fetchedObject.length
          ? []
          : sectors.fetchedObject
      )
      return
    }
    setFieldValue('sectorIds', value)
  }

  return (
    <>
      <Box display={'flex'} gap={2}>
        {!readOnly && headquarters.fetchedObject ? (
          <Box sx={{ width: '100%' }}>
            <FormControl fullWidth size="small">
              <InputLabel id="headquarterId-label">
                {t('backOfficeUserDetailPage.headquarters')}
              </InputLabel>
              <Select
                MenuProps={{ className: classes.menuHeadquarters }}
                labelId="headquarterId-label"
                label={'Sedi'}
                name="headquarterIds"
                variant="outlined"
                multiple
                value={
                  headquarters?.fetchedObject &&
                  headquarters.fetchedObject.filter((h) =>
                    values.headquarterIds.find(
                      (selectedHq) => selectedHq.id === h.id
                    )
                  )
                }
                renderValue={(selected) => {
                  return selected
                    ? selected.map((head) => head.name).join(', ')
                    : t('common.select')
                }}
                disabled={!!readOnly}
                onChange={handleHeadquarterChange}
              >
                <MenuItem value={'all'}>
                  <Checkbox
                    name="all"
                    checked={
                      values.headquarterIds.length ===
                      headquarters.fetchedObject.length
                    }
                  />
                  <ListItemText primary={t('common.selectAll')} />
                </MenuItem>
                {headquarters.fetchedObject.map((c) => {
                  return (
                    <MenuItem key={c.id} value={c}>
                      <Checkbox
                        name="subsidiary"
                        checked={
                          !!values.headquarterIds.find(
                            (subsidiary) => subsidiary.id === c.id
                          )
                        }
                      />
                      <ListItemText primary={c.name} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        ) : (
          readOnly &&
          headquarters.fetchedObject && (
            <>
              <Paper
                sx={{
                  width: '100%',
                  padding: '0px 8px 0px 8px',
                  position: 'relative',
                  '&:after': {
                    content: '" "',
                    position: 'absolute',
                    bottom: 0,
                    background:
                      'linear-gradient(to bottom, transparent, white)',
                    height: '50px',
                    width: '100%',
                  },
                }}
              >
                <Typography variant="h6">
                  {t('challengeDetailPage.headquarters')}
                </Typography>
                <List
                  className="headertersList"
                  sx={{
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    paddingTop: 0,
                    paddingBottom: '32px',
                    '& ul': { padding: 0 },
                  }}
                >
                  {values.headquarterIds.map((headquarter) => (
                    <li key={headquarter.id}>{headquarter.name}</li>
                  ))}
                </List>
              </Paper>
            </>
          )
        )}
        {!readOnly && sectors.fetchedObject ? (
          <Box sx={{ width: '100%' }}>
            <FormControl fullWidth size="small">
              <InputLabel id="sectorId-label">
                {t('challengeDetailPage.sectors')}
              </InputLabel>
              <Select
                MenuProps={{ className: classes.menuHeadquarters }}
                labelId="sectorId-label"
                label={'Settori'}
                name="sectorIds"
                variant="outlined"
                multiple
                value={
                  sectors?.fetchedObject &&
                  sectors.fetchedObject.filter((s) =>
                    values.sectorIds.find(
                      (selectedSector) => selectedSector.id === s.id
                    )
                  )
                }
                renderValue={(selected) => {
                  return selected
                    ? selected.map((head) => head.name).join(', ')
                    : t('common.select')
                }}
                disabled={!!readOnly}
                onChange={handleSectorChange}
              >
                <MenuItem value={'all'}>
                  <Checkbox
                    name="all"
                    checked={
                      values.sectorIds.length === sectors.fetchedObject.length
                    }
                  />
                  <ListItemText primary={t('common.selectAll')} />
                </MenuItem>
                {sectors.fetchedObject.map((c) => {
                  return (
                    <MenuItem key={c.id} value={c}>
                      <Checkbox
                        name="sector"
                        checked={
                          !!values.sectorIds?.find(
                            (sector) => sector.id === c.id
                          )
                        }
                      />
                      <ListItemText primary={c.name} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        ) : (
          readOnly &&
          sectors.fetchedObject && (
            <>
              <Paper
                sx={{
                  width: '100%',
                  padding: '0px 8px 0px 8px',
                  position: 'relative',
                  '&:after': {
                    content: '" "',
                    position: 'absolute',
                    bottom: 0,
                    background:
                      'linear-gradient(to bottom, transparent, white)',
                    height: '50px',
                    width: '100%',
                  },
                }}
              >
                <Typography variant="h6">
                  {t('challengeDetailPage.sectors')}
                </Typography>
                <List
                  className="headertersList"
                  sx={{
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    paddingTop: 0,
                    paddingBottom: '32px',
                    '& ul': { padding: 0 },
                  }}
                >
                  {values.sectorIds.map((sector) => (
                    <li key={sector.id}>{sector.name}</li>
                  ))}
                </List>
              </Paper>
            </>
          )
        )}
      </Box>
      <Typography variant="p" sx={{ marginTop: '4px !important' }}>
        {t('challengeDetailPage.help_sectors_headquarters')}
      </Typography>
    </>
  )
}

HeadquartersAndSectors.propTypes = {
  companyId: T.string,
  values: T.object,
  setFieldValue: T.func,
  readOnly: T.bool,
}
