import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import { Form, Formik } from 'formik'
import T from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import useFetchCompanyRewardingType from '../../../hooks/useFetchCompanyRewardingType'
import useFetchCurrentAgreements from '../../../hooks/useFetchCurrentAgreements'
import useFetchHeadquarters from '../../../hooks/useFetchHeadquarters'
import useFetchRewardingTypes, {
  rewardingTypeIds,
} from '../../../hooks/useFetchRewardingTypes'
import useFetchSectors from '../../../hooks/useFetchSectors'

import request from '../../../lib/request'
import Spinner from '../../atoms/Spinner'
import TextField from '../../atoms/TextField'
import HeadquartersAndSectors from '../../molecules/HeadquartersAndSectors'
import RewardingTransactionsSummary from '../RewardingTransactionsSummary'
import useSnackbar from '../Snackbar/useSnackbar'
import { RewardingSchema } from './validators'

const slotProps = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 20],
        },
      },
    ],
  },
}

const nullOrNumber = (x) => (x ? Number(x) : null)

const useStyles = makeStyles((theme) => ({
  menuHeadquarters: {
    height: 400,
  },
}))

export default function RewardingForm({ companyId, companyRewardingTypeId }) {
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()
  const classes = useStyles()

  const { isAdmin } = useAuth()

  const isNew = !companyRewardingTypeId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const {
    fetchedObject: companyRewardingType,
    isFetching: isFetchingCompanyRewardingType,
    fetchError: fetchCompanyRewardingTypeError,
    refetch: refetchCompanyRewardingType,
  } = useFetchCompanyRewardingType(companyRewardingTypeId)

  const {
    fetchedObject: rewardingTypes,
    isFetching: isFetchingRewardingTypes,
    fetchError: fetchRewardingTypesError,
  } = useFetchRewardingTypes()

  const {
    fetchedObject: agreements,
    isFetching: isFetchingAgreements,
    fetchError: fetchAgreementsError,
  } = useFetchCurrentAgreements()

  const sectors = useFetchSectors({ companyId })
  const headquarters = useFetchHeadquarters({ companyId })

  useEffect(() => {
    if (
      fetchCompanyRewardingTypeError ||
      fetchRewardingTypesError ||
      fetchAgreementsError
    ) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${
          fetchCompanyRewardingTypeError ||
          fetchRewardingTypesError ||
          fetchAgreementsError
        }`,
      })
    }
  }, [
    fetchCompanyRewardingTypeError,
    fetchRewardingTypesError,
    fetchRewardingTypesError,
    showSnackbarMessage,
    t,
  ])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        rewardingTypeId: values.rewardingTypeId,
        startDate: startOfDay(values.startDate).toISOString(),
        endDate: values.endDate && endOfDay(values.endDate).toISOString(),
        attrs: {
          sectorIds: values.sectorIds.map((sect) => sect.id),
          headquarterIds: (values.headquarterIds || []).map((head) => head.id),
        },
      }

      if (
        data.rewardingTypeId === rewardingTypeIds.VOUCHER ||
        data.rewardingTypeId === rewardingTypeIds.EXTERNAL_WITH_REDEMPTION
      ) {
        data.attrs = {
          ...data.attrs,
          creditValueInEuro: values.creditValueInEuro,
          redemptionAmounts: values.redemptionAmounts,
          maxCreditsPerDay: nullOrNumber(values.maxCreditsPerDay),
          maxCreditsPerWeek: nullOrNumber(values.maxCreditsPerWeek),
          maxCreditsPerMonth: nullOrNumber(values.maxCreditsPerMonth),
          rewardingMessage: values.rewardingMessage,
        }
      }

      if (data.rewardingTypeId === rewardingTypeIds.EXTERNAL_WITH_REDEMPTION) {
        data.attrs.companyEmails = values.companyEmails
      }

      if (data.rewardingTypeId === rewardingTypeIds.AGREEMENT) {
        data.attrs.agreementId = values.agreementId
        data.attrs.agreementReportEmails = values.agreementReportEmails
      }

      if (isNew) {
        // Adjust API endpoint for creation
        await request.post(`/admin/rewarding/company`, {
          ...data,
          companyId,
        })
        actions.setSubmitting(false)
        history.replace(`/company/${companyId}/rewardings`)
      } else {
        // Adjust API endpoint for update
        const { id: newCompanyRewardingTypeId } = await request.patch(
          `/admin/rewarding/company/${companyRewardingTypeId}`,
          data
        )
        actions.setSubmitting(false)
        setPageMode('view')
        if (newCompanyRewardingTypeId !== companyRewardingTypeId) {
          history.replace(
            `/company/${companyId}/rewarding/${newCompanyRewardingTypeId}`
          )
        } else {
          Object.assign(companyRewardingType, data)
        }
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.message,
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('rewardingPage.deleteConfirmDescription'),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(
            `/admin/rewarding/company/${companyRewardingTypeId}`
          )
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/company/${companyId}/rewardings`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetchingCompanyRewardingType ||
    isFetchingRewardingTypes ||
    isFetchingAgreements ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={900} ml={0} mt={2}>
      <Formik
        validationSchema={RewardingSchema(t)}
        initialValues={{
          rewardingTypeId: companyRewardingType?.rewardingTypeId || '',
          startDate: companyRewardingType?.startDate
            ? new Date(companyRewardingType.startDate)
            : '',
          endDate: companyRewardingType?.endDate
            ? new Date(companyRewardingType.endDate)
            : '',
          creditValueInEuro:
            companyRewardingType?.attrs?.creditValueInEuro || 0.5,
          redemptionAmounts:
            companyRewardingType?.attrs?.redemptionAmounts || '',
          maxCreditsPerDay: companyRewardingType?.attrs?.maxCreditsPerDay || '',
          maxCreditsPerWeek:
            companyRewardingType?.attrs?.maxCreditsPerWeek || '',
          maxCreditsPerMonth:
            companyRewardingType?.attrs?.maxCreditsPerMonth || '',
          companyEmails: companyRewardingType?.attrs?.companyEmails || '',
          rewardingMessage: companyRewardingType?.attrs?.rewardingMessage || '',
          sectorIds: [...(sectors?.fetchedObject || [])].filter(
            (sector) =>
              companyRewardingType?.attrs?.sectorIds?.indexOf(sector.id) > -1
          ),
          headquarterIds: [...(headquarters?.fetchedObject || [])].filter(
            (headquarter) =>
              companyRewardingType?.attrs?.headquarterIds?.indexOf(
                headquarter.id
              ) > -1
          ),
          agreementId: companyRewardingType?.attrs?.agreementId || '',
          agreementReportEmails:
            companyRewardingType?.attrs?.agreementReportEmails || '',
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({
          isValid,
          dirty,
          isSubmitting,
          values,
          handleChange,
          setFieldValue,
        }) => {
          return (
            <Form>
              {!isFetchingRewardingTypes && (
                <>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ marginBottom: '1rem' }}
                  >
                    <InputLabel id="rewardingTypeId-label">
                      {t('entities.rewarding.rewardingType')}
                    </InputLabel>
                    <Select
                      // MenuProps={{ className: classes.menuHeadquarters }}
                      labelId="rewardingTypeId-label"
                      label={t('entities.rewarding.rewardingType')}
                      name="rewardingTypeId"
                      disabled={!isNew}
                      value={values.rewardingTypeId}
                      onChange={handleChange}
                    >
                      {rewardingTypes.map((rt) => (
                        <MenuItem key={rt.id} value={rt.id}>
                          {t(`entities.rewardingTypes.${rt.i18nLabel}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <HeadquartersAndSectors
                    companyId={companyId}
                    values={values}
                    setFieldValue={setFieldValue}
                    readOnly={readOnly}
                  />

                  <Box display="flex" gap={2} style={{ marginTop: '1rem' }}>
                    <DatePicker
                      margin="dense"
                      label={t('entities.rewarding.startDate')}
                      name="startDate"
                      autoOk
                      readOnly={readOnly}
                      value={
                        values.startDate ? new Date(values.startDate) : null
                      }
                      onChange={(newDate) =>
                        setFieldValue('startDate', newDate)
                      }
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      size="small"
                      required
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                      inputProps={{
                        autoComplete: 'off',
                        readOnly,
                        size: 'small',
                        required: true,
                      }}
                    />
                    <DatePicker
                      margin="dense"
                      label={t('entities.rewarding.endDate')}
                      name="endDate"
                      autoOk
                      readOnly={readOnly}
                      value={values.endDate ? new Date(values.endDate) : null}
                      onChange={(newDate) => setFieldValue('endDate', newDate)}
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      size="small"
                      inputProps={{
                        autoComplete: 'off',
                        readOnly,
                        size: 'small',
                      }}
                    />
                  </Box>

                  {(values.rewardingTypeId === rewardingTypeIds.VOUCHER ||
                    values.rewardingTypeId ===
                      rewardingTypeIds.EXTERNAL_WITH_REDEMPTION) && (
                    <>
                      <Box sx={{ whiteSpace: 'nowrap' }}>
                        <TextField
                          margin="normal"
                          inputProps={{
                            autoComplete: 'off',
                            readOnly,
                          }}
                          size="small"
                          label={t('entities.rewarding.creditValueInEuro')}
                          InputLabelProps={{ shrink: true }}
                          required
                          name="creditValueInEuro"
                        />

                        <TextField
                          margin="normal"
                          inputProps={{
                            autoComplete: 'off',
                            readOnly,
                          }}
                          size="small"
                          sx={{ width: 300, ml: 1 }}
                          label={t('entities.rewarding.redemptionAmounts')}
                          InputLabelProps={{ shrink: true }}
                          required
                          name="redemptionAmounts"
                        />
                      </Box>

                      <Box sx={{ whiteSpace: 'nowrap' }}>
                        <Tooltip
                          title={t('entities.rewarding.maxCreditsTooltip')}
                          slotProps={slotProps}
                        >
                          <span>
                            <TextField
                              margin="normal"
                              inputProps={{
                                autoComplete: 'off',
                                readOnly,
                              }}
                              size="small"
                              label={t('entities.rewarding.maxCreditsPerDay')}
                              InputLabelProps={{ shrink: true }}
                              name="maxCreditsPerDay"
                            />
                          </span>
                        </Tooltip>

                        <Tooltip
                          title={t('entities.rewarding.maxCreditsTooltip')}
                          slotProps={slotProps}
                        >
                          <span>
                            <TextField
                              margin="normal"
                              inputProps={{
                                autoComplete: 'off',
                                readOnly,
                              }}
                              size="small"
                              sx={{ ml: 1 }}
                              label={t('entities.rewarding.maxCreditsPerWeek')}
                              InputLabelProps={{ shrink: true }}
                              name="maxCreditsPerWeek"
                            />
                          </span>
                        </Tooltip>

                        <Tooltip
                          title={t('entities.rewarding.maxCreditsTooltip')}
                          slotProps={slotProps}
                        >
                          <span>
                            <TextField
                              margin="normal"
                              inputProps={{
                                autoComplete: 'off',
                                readOnly,
                              }}
                              size="small"
                              sx={{ ml: 1 }}
                              label={t('entities.rewarding.maxCreditsPerMonth')}
                              InputLabelProps={{ shrink: true }}
                              name="maxCreditsPerMonth"
                            />
                          </span>
                        </Tooltip>
                      </Box>
                    </>
                  )}

                  {values.rewardingTypeId ===
                    rewardingTypeIds.EXTERNAL_WITH_REDEMPTION && (
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                      <TextField
                        margin="normal"
                        inputProps={{
                          autoComplete: 'off',
                          readOnly,
                        }}
                        size="small"
                        sx={{ width: '100%' }}
                        label={t('entities.rewarding.companyEmails')}
                        InputLabelProps={{ shrink: true }}
                        name="companyEmails"
                      />
                    </Box>
                  )}

                  {(values.rewardingTypeId === rewardingTypeIds.VOUCHER ||
                    values.rewardingTypeId ===
                      rewardingTypeIds.EXTERNAL_WITH_REDEMPTION) && (
                    <Box sx={{ whiteSpace: 'nowrap' }}>
                      <TextField
                        margin="normal"
                        inputProps={{
                          autoComplete: 'off',
                          readOnly,
                        }}
                        size="small"
                        sx={{ width: '100%' }}
                        label={t('entities.rewarding.rewardingMessage')}
                        InputLabelProps={{ shrink: true }}
                        name="rewardingMessage"
                      />
                    </Box>
                  )}

                  {values.rewardingTypeId === rewardingTypeIds.AGREEMENT && (
                    <>
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{ marginTop: '1rem' }}
                      >
                        <InputLabel id="agreementId-label">
                          {t('rewardingPage.agreementId')}
                        </InputLabel>
                        <Select
                          // MenuProps={{ className: classes.menuHeadquarters }}
                          labelId="agreementId-label"
                          label={t('entities.rewarding.agreementId')}
                          name="agreementId"
                          disabled={!!readOnly}
                          value={values.agreementId}
                          onChange={handleChange}
                          required
                        >
                          <MenuItem value="">{t('common.select')}</MenuItem>
                          {agreements.map((a) => (
                            <MenuItem key={a.id} value={a.id}>
                              {a.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <Box sx={{ whiteSpace: 'nowrap' }}>
                        <TextField
                          margin="normal"
                          inputProps={{
                            autoComplete: 'off',
                            readOnly,
                          }}
                          size="small"
                          sx={{ width: '100%' }}
                          label={t('entities.rewarding.agreementReportEmails')}
                          InputLabelProps={{ shrink: true }}
                          name="agreementReportEmails"
                          required
                        />
                      </Box>
                    </>
                  )}
                </>
              )}

              <Container>
                {readOnly ? (
                  <>
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                    {isAdmin && (
                      <>
                        <Button
                          onClick={handleEdit}
                          color="primary"
                          aria-label={t('common.edit')}
                        >
                          {t('common.edit')}
                        </Button>
                        <Button
                          onClick={handleDelete}
                          color="primary"
                          aria-label={t('common.delete')}
                        >
                          {t('common.delete')}
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isNew ? (
                      <Button
                        onClick={() => history.goBack()}
                        color="secondary"
                        aria-label={t('common.back')}
                      >
                        {t('common.back')}
                      </Button>
                    ) : (
                      <Button
                        type="reset"
                        color="secondary"
                        aria-label={t('common.cancel')}
                      >
                        {t('common.cancel')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={!isValid || !dirty || isSubmitting}
                      color="primary"
                    >
                      {t('common.save')}
                    </Button>
                  </>
                )}
              </Container>
            </Form>
          )
        }}
      </Formik>

      {companyRewardingType?.rewardingTypeId === rewardingTypeIds.VOUCHER && (
        <>
          <Divider sx={{ m: 3 }} />

          <Card>
            <CardContent>
              <Typography variant="h5">
                {t('rewardingPage.transactionsSummary.title')}
              </Typography>
              <RewardingTransactionsSummary
                companyRewardingTypeId={companyRewardingTypeId}
                refetchCompanyRewardingType={refetchCompanyRewardingType}
              />
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  )
}

RewardingForm.propTypes = {
  companyId: T.string.isRequired,
  companyRewardingTypeId: T.string,
}
