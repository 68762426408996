import * as Yup from 'yup'

export const AgreementSchema = (t) => {
  const invalidValue = t('common.invalidValue')
  const fieldRequired = t('common.fieldRequired')
  const rideTypeRequired = t('common.rideTypeRequired')

  return Yup.object().shape({
    name: Yup.string().required(fieldRequired),
    startDate: Yup.string().required(fieldRequired),
    rideTypeIds: Yup.array()
      .required(rideTypeRequired)
      .min(1, rideTypeRequired),
    promotingCompanyId: Yup.string().required(fieldRequired),
    promotingCompanyEmail: Yup.string().email().required(fieldRequired),
    kmRefundInEuro: Yup.number()
      .typeError(invalidValue)
      .required(fieldRequired),
    maxEuroPerUserMonthly: Yup.number()
      .typeError(invalidValue)
      .required(fieldRequired),
  })
}
