import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import useCompaniesSectors from '../../../hooks/useCompaniesSectors'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'
import AgreementFilterBar from './AgreementFilterBar'

const fetchAgreements = async (query, filters) => {
  const search = filters.search || ''
  const companyIds = filters.companyIds || ''
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''

  const { items: data, total: totalCount } = await request.get(
    `/admin/agreements?search=${search}&companyIds=${companyIds}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )
  const offset = query.pageSize * query.page

  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function AgreementTable() {
  const history = useHistory()
  const companiesSectors = useCompaniesSectors()
  const [filters, setFilters] = useState({
    search: '',
    companyIds: companiesSectors.length === 1 ? [companiesSectors[0].id] : [],
    archived: false,
  })
  const tableRef = useRef()
  const { showSnackbarAgreement } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    { title: t('agreementsPage.tableHeaders.name'), field: 'name' },
    {
      title: t('agreementsPage.tableHeaders.startDate'),
      field: 'startDate',
      render: (item) =>
        item.startDate ? new Date(item.startDate).toLocaleDateString() : '',
    },
    {
      title: t('agreementsPage.tableHeaders.endDate'),
      field: 'endDate',
      render: (item) =>
        item.endDate ? new Date(item.endDate).toLocaleDateString() : '',
    },
    {
      title: t('agreementsPage.tableHeaders.promotingCompanyName'),
      field: 'promotingCompanyName',
    },
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    async (query) => {
      return fetchAgreements(query, filters).catch((e) => {
        showSnackbarAgreement({
          open: true,
          severity: 'error',
          agreement: `${t('common.unexpectedError')} ${e.agreement}`,
        })
        throw new Error('')
      })
    },
    [filters, showSnackbarAgreement, t]
  )

  const onAddClick = () => history.push(`/agreement`)

  return (
    <Card className={classes.root}>
      <AgreementFilterBar
        filters={filters}
        setFilters={setFilters}
        companiesSectors={companiesSectors}
        onAddClick={onAddClick}
      />
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
        actions={[
          {
            icon: '',
            text: t('common.detail'),
            onClick: (e, rowData) => {
              history.push(`/agreement/${rowData.id}`)
            },
          },
        ]}
      />
    </Card>
  )
}
