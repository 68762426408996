import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AgreementTable from '../organisms/AgreementTable'

export default function Agreements() {
  const { t } = useTranslation()

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('agreementsPage.title')}</Typography>
      <AgreementTable />
    </div>
  )
}
