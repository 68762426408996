import React from 'react'
import { useParams } from 'react-router-dom'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AgreementForm from '../organisms/AgreementForm'

export default function AgreementDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const isNew = !id

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">
        {t(
          isNew
            ? 'agreementDetailPage.newTitle'
            : 'agreementDetailPage.detailTitle'
        )}
      </Typography>
      <AgreementForm agreementId={id} />
    </div>
  )
}
