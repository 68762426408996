import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchCurrentAgreements() {
  const agreements = useCallback(async () => {
    return request.get('/admin/agreements/current')
  }, [])

  return useFetch(agreements)
}
