import React from 'react'
import T from 'prop-types'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Action from '../../atoms/Action'
import Table from '../../Table'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function ParticipatingCompanies({ participatingCompanies }) {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    {
      title: t('agreementDetailPage.participatingCompanies.companyName'),
      field: 'companyName',
      sorting: false,
    },
    {
      title: t('agreementDetailPage.participatingCompanies.companySectorNames'),
      field: 'companySectorNames',
      sorting: false,
    },
    {
      title: t('agreementDetailPage.participatingCompanies.startDate'),
      field: 'startDate',
      render: (item) =>
        item.startDate ? new Date(item.startDate).toLocaleDateString() : '',
      sorting: false,
    },
    {
      title: t('agreementDetailPage.participatingCompanies.endDate'),
      field: 'endDate',
      render: (item) =>
        item.endDate ? new Date(item.endDate).toLocaleDateString() : '',
      sorting: false,
    },
  ]

  return (
    <Card className={classes.root}>
      <Table
        style={{ boxShadow: 'none' }}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={participatingCompanies}
        options={{ paging: false }}
        actions={[
          {
            icon: '',
            text: t('common.detail'),
            onClick: (e, rowData) => {
              history.push(
                `/company/${rowData.companyId}/rewarding/${rowData.id}`
              )
            },
          },
        ]}
      />
    </Card>
  )
}

ParticipatingCompanies.propTypes = {
  participatingCompanies: T.arrayOf(
    T.shape({
      companyId: T.string.isRequired,
      companyName: T.string.isRequired,
      companySectorNames: T.string,
      startDate: T.object.isRequired,
      endDate: T.object,
    })
  ).isRequired,
}
